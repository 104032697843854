import { z } from "zod";

/**
 * Compiles a path from segments
 * @param segments string[]
 * @returns string path
 */

// validator for path segments
const pathValidator = z.array(z.string());
export const compilePath = (...segments: string[]): string => {
  // validate segments
  pathValidator.parse(segments);

  // return path compiled by segements
  return "/" + segments.join("/");
};

// Util for simple path matching
export const matchPath = (path: string, ...segments: string[]): boolean => {
  const currentSegments = path.split("/").filter((p) => p !== "");
  let isMatch = true;
  segments.forEach((s, i) => {
    if (currentSegments[i] !== s) {
      isMatch = false;
    }
  });
  return isMatch;
};

// Utils for getting the correct base path when routing within tools
export const getAppRootPath = (
  slug: "chat-ai" | "image-ai" | "translate-ai" | "isearch",
) => {
  // Check for domain
  // TODO: Check url for sub domain chat-ai.eopharma-app.com > then return basepath ""
  return slug;
};
